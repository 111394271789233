import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { MenuItem, Select } from '@mui/material'
import { createProviderAccountingEntriesCsv } from '../../../services/APIClient'

export function ProviderReportModal() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button className="btn btn-success" onClick={handleOpen}>
        Provider reconciliation reports
      </Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Provider reconciliation reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <AccountingEntriesReport />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

function AccountingEntriesReport() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [bankAccountType, setBankAccountType] = useState('PAYMENT_COLLECTION')
  const [isLoading, setIsLoading] = useState(false)
  const today = new Date()
  const maxReportDate = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0]

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  const handleDownloadClick = () => {
    setIsLoading(true)
    createProviderAccountingEntriesCsv(startDate, endDate, bankAccountType)
      .catch(() => alert('Something went wrong. Report could not be downloaded. Please, contact system administrator.'))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDateChange = (propertyName, newValue) => {
    if (propertyName === 'startDate') {
      setStartDate(newValue)
    } else if (propertyName === 'endDate') {
      setEndDate(newValue)
    }
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Find all accounting entries from provider</label>
        </div>
        <div className="row">
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">Initial date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={(event) => onDateChange('startDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">End date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              onChange={(event) => onDateChange('endDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-4 col-4">
            <Select
              value={bankAccountType}
              name="bankAccountType"
              id="bankAccountType"
              onChange={(e) => setBankAccountType(e.target.value)}
              className="select"
            >
              <MenuItem value="PAYMENT_COLLECTION">PAYMENT COLLECTION</MenuItem>
              <MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
            </Select>
          </div>
          <button
            className="buttonSendDate"
            onClick={handleDownloadClick}
            disabled={startDate === null || endDate === null || isLoading}
          >
            Download csv
          </button>
        </div>
      </div>
    </div>
  )
}
